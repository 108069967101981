import React, { useState } from "react";
import { NalogIzvjestajModel } from "../../../../models/IzvjestajModel";
import * as Yup from "yup";
import { useFormik } from "formik";
import { periodicniIzvjestajKontrole } from "../../../../api/izvjestaj";
import notifyToast from "../../../../components/Toast/Toast";
import { ToastContainer } from "react-toastify";
import TitleComponent from "../../../../components/TitleComponent/TitleComponent";
import clsx from "clsx";
import moment from "moment";
import {ZapisiStavkeModel} from "../../../../models/ZapisiProizvodnjeModel";

const PeriodicniIzvjestajKontrolePanela: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [nalogData, setNalog] = useState<NalogIzvjestajModel[]>([]);

    // Initial values for the form
    const initialValues = {
        datumOd: "",
        datumDo: "",
    };

    // Validation schema should match initialValues fields
    const validationSchema = Yup.object({
        datumOd: Yup.string().required("Datum Od is required"),
        datumDo: Yup.string().required("Datum Do is required"),
    });

    // Formik setup
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true);
            try {
                const response = await periodicniIzvjestajKontrole(values);
                setNalog(response.data["data"]);
            } catch (error: any) {
                console.error("Error: ", error);
                notifyToast(false, error.response?.data?.message || "Error occurred while fetching data.");
                setStatus(error);
            } finally {
                setLoading(false);
                setSubmitting(false);
            }
        },
    });

    // Helper function to get Receptura value
    const getRecepturaValue = (stavke_zapisa: ZapisiStavkeModel[] | undefined) => {
        if (!stavke_zapisa || !Array.isArray(stavke_zapisa)) return "N/A";
        const recepturaRecord = stavke_zapisa.find((stavka) => stavka.MetaKey === "RECEPTURA");
        return recepturaRecord ? recepturaRecord.MetaValue : "N/A";
    };

    // Helper function to get Prajmer value
    const getPrajmerValue = (stavke_zapisa: ZapisiStavkeModel[] | undefined) => {
        if (!stavke_zapisa || !Array.isArray(stavke_zapisa)) return "N/A";
        const prajmerRecord = stavke_zapisa.find((stavka) => stavka.MetaKey === "PRAJMER-KOLICINA");
        return prajmerRecord ? prajmerRecord.MetaValue : "N/A";
    };

    return (
        <div className="card mb-5 mb-xl-10">
            <ToastContainer />
            <div className="card-header border-0 cursor-pointer" role="button">
                <div className="card-title m-0">
                    <TitleComponent name="Periodični Izvještaj Kontrole Kvaliteta Panela" />
                </div>
            </div>
            <div id="kt_account_profile_details" className="collapse show">
                <div className="card-body border-top p-9">
                    {nalogData.length === 0 ? (
                        <div className="row">
                            <div className="col-12">
                                <form onSubmit={formik.handleSubmit} noValidate className="form">
                                    <div className="card-body border-top p-9">
                                        <div className="row">
                                            {/* Datum Od Field */}
                                            <div className="col-4 mb-6">
                                                <label>Datum od</label>
                                                <input
                                                    type="date"
                                                    placeholder="Datum od"
                                                    {...formik.getFieldProps("datumOd")}
                                                    className={clsx("form-control form-control-lg form-control-solid", {
                                                        "is-invalid": formik.touched.datumOd && formik.errors.datumOd,
                                                        "is-valid": formik.touched.datumOd && !formik.errors.datumOd,
                                                    })}
                                                />
                                                {formik.touched.datumOd && formik.errors.datumOd ? (
                                                    <div className="invalid-feedback">{formik.errors.datumOd}</div>
                                                ) : null}
                                            </div>
                                            {/* Datum Do Field */}
                                            <div className="col-4 mb-6">
                                                <label>Datum do</label>
                                                <input
                                                    type="date"
                                                    placeholder="Datum do"
                                                    {...formik.getFieldProps("datumDo")}
                                                    className={clsx("form-control form-control-lg form-control-solid", {
                                                        "is-invalid": formik.touched.datumDo && formik.errors.datumDo,
                                                        "is-valid": formik.touched.datumDo && !formik.errors.datumDo,
                                                    })}
                                                />
                                                {formik.touched.datumDo && formik.errors.datumDo ? (
                                                    <div className="invalid-feedback">{formik.errors.datumDo}</div>
                                                ) : null}
                                            </div>
                                            {/* Submit Button */}
                                            <div className="card-footer d-flex justify-content-end py-6 px-9">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    disabled={loading || formik.isSubmitting || !formik.isValid}
                                                >
                                                    {!loading && "Potvrdi"}
                                                    {loading && (
                                                        <span className="indicator-progress" style={{ display: "block" }}>
                              Please wait...{" "}
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-12">
                                <h2 className="mb-6">Kontrola kvaliteta</h2>
                                <table className="table table-bordered">
                                    <thead className="thead-dark">
                                    <tr className="bg-warning">
                                        <th rowSpan={2}>#</th>
                                        <th rowSpan={2}>Datum</th>
                                        <th rowSpan={2}>Nalog</th>
                                        <th colSpan={3}>Gustoća (A8)</th>
                                        <th colSpan={3}>Zatezna čvrstoća (A1)</th>
                                        <th colSpan={3}>Pritisna čvrstoca (A2)</th>
                                        <th colSpan={3}>Čvrstoća smicanja (A9)</th>
                                        <th rowSpan={2}>Napon gužvanja</th>
                                        <th rowSpan={2}>Rolne Gornji Lim</th>
                                        <th rowSpan={2}>Rolne Donji Lim</th>
                                        <th rowSpan={2}>Lamda</th>
                                        <th rowSpan={2}>Receptura</th>
                                        <th rowSpan={2}>Količina <br /> ljepila/prajmera</th>
                                    </tr>
                                    <tr className="bg-warning">
                                        <th>1</th>
                                        <th>2</th>
                                        <th>3</th>
                                        <th>1</th>
                                        <th>2</th>
                                        <th>3</th>
                                        <th>1</th>
                                        <th>2</th>
                                        <th>3</th>
                                        <th>1</th>
                                        <th>2</th>
                                        <th>3</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {nalogData.map((nalog, index) => (
                                        <tr key={nalog.Id}>
                                            <td>{index + 1}</td>
                                            <td>{moment(nalog.Kreirano).format("DD-MM-YYYY HH:mm")}</td>
                                            <td>{nalog.paneli_proizvodnja?.brojNaloga}</td>
                                            <td>{nalog.GustocaUzorka1 || "----"}</td>
                                            <td>{nalog.GustocaUzorka2 || "----"}</td>
                                            <td>{nalog.GustocaUzorka3 || "----"}</td>
                                            <td>{nalog.A1ZateznaCvrstocaU1 || "----"}</td>
                                            <td>{nalog.A1ZateznaCvrstocaU2 || "----"}</td>
                                            <td>{nalog.A1ZateznaCvrstocaU3 || "----"}</td>
                                            <td>{nalog.A2PritisnaCvrstocaUzorak1 || "----"}</td>
                                            <td>{nalog.A2PritisnaCvrstocaUzorak2 || "----"}</td>
                                            <td>{nalog.A2PritisnaCvrstocaUzorak3 || "----"}</td>
                                            <td>{nalog.A3CvrstocaSmicanja1 || "----"}</td>
                                            <td>{nalog.A3CvrstocaSmicanja2 || "----"}</td>
                                            <td>{nalog.A3CvrstocaSmicanja3 || "----"}</td>
                                            <td>{nalog.A5NaponGuzvanja || "----"}</td>
                                            <td>{nalog.paneli_proizvodnja?.rolneG || "----"}</td>
                                            <td>{nalog.paneli_proizvodnja?.rolneD || "----"}</td>
                                            <td>{nalog.A10Lamda || "----"}</td>
                                            <td>{getRecepturaValue(nalog.zapisi_proizvodnje?.stavke_zapisa)}</td>
                                            <td>{getPrajmerValue(nalog.zapisi_proizvodnje?.stavke_zapisa)}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PeriodicniIzvjestajKontrolePanela;
