import {KTCard, KTCardBody} from "../../../_websoft/helpers";
import {ToastContainer} from "react-toastify";
import RedoviModal from "../repromaterijali/Izvjestaji/components/RedoviModal";
import StanjePoBojiModal from "../repromaterijali/Izvjestaji/components/StanjePoBojiModal";
import TitleComponent from "../../components/TitleComponent/TitleComponent";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Link} from "react-router-dom";
const RazniIzvjestaji:React.FC = () => {

    return (
        <KTCard>
            <ToastContainer />
            <KTCardBody className='py-5'>
                <TitleComponent name='Izvještaji'/>
                <div className='row py-5'>
                    <div className='col-lg-4 col-md-6'>
                        <Link to='../repromaterijal/izvjestaji'>
                            <button className='btn btn-success p-10'>
                                Repromaterijali
                            </button>
                        </Link>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <Link to='../paneli/izvjestaji'>
                            <button className='btn btn-primary p-10'>
                                Paneli
                            </button>
                        </Link>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <Link to='../uposlenici/izvjestaj'>
                            <button className='btn btn-info p-10'>
                                Uposlenici | Norme
                            </button>
                        </Link>
                    </div>
                </div>
                <div className='separator mt-4 mb-4'></div>
                <div className='row py-5'>
                    <div className='col-lg-4 col-md-6'>
                        <Link to='../repromaterijal/izvjestaji'>
                            <button className='btn btn-dark p-10'>
                                Količina artikala po sredstvu, uposleniku
                            </button>
                        </Link>
                    </div>
                </div>
            </KTCardBody>
        </KTCard>
    );
}
export default RazniIzvjestaji;
